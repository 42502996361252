import { reactive, toRefs, ref } from "@vue/composition-api";
import { getCurrentUser } from "@/plugins/util";
import { ACTION_SIGN_OUT, ACTION_SET_USER } from "@/store/const";
export default function(refs, root) {
  const { $store, $router } = root;
  const toast = (status, message, options = {}, isServerResponse = true) => {
    // toast(true,'Test Toast',{color:'',timeout:0}, false)
    if (!message || !refs.weeToastRef) return;
    const defaultOption = isServerResponse
      ? { color: status ? "success" : "error" }
      : {};
    refs.weeToastRef.toast(message, Object.assign(defaultOption, options));
  };
  const baseLogout = () => {
    $store.dispatch(ACTION_SIGN_OUT);
    setTimeout(() => {
      $router.push(`/auth/signin`);
    }, 500);
  };
  const baseUpdateUserStore = async (data = {}) => {
    // {name:'chanavee'}
    const currentUser = await getCurrentUser();
    $store.dispatch(ACTION_SET_USER, Object.assign(currentUser, data));
  };
  const state = reactive({
    status: true,
    statusMessage: null
  });
  const snackbar = ref(false);
  return { ...toRefs(state), snackbar, toast, baseLogout, baseUpdateUserStore };
}
