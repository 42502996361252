<template>
  <div
    :class="backgroudBg ? 'login-filter' : ''"
    class="fill-height fill-width"
  >
    <v-app-bar
      class="transparent"
      absolute
      elevation="0"
      dense
      :dark="backgroudBg"
    >
      <base-wee-logo />

      <v-spacer />

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->
      <core-locale />
      <!-- <v-switch
        v-model="backgroudBg"
        color="primary"
        class="ma-0 pa-0"
        hide-details
        label="Bg"
      /> -->
    </v-app-bar>

    <v-container :class="backgroudBg ? 'login' : ''" fluid class="fill-height">
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="ma-auto">
          <v-card class="px-8 pt-6 pb-12" elevation="0">
            <v-card-title class="text-center font-weight-black">{{
              $t("app.name")
            }}</v-card-title>
            <v-card-subtitle
              class="mb-1 mt-1 subtitle-2 grey--text text--lighten-1"
              >{{
                $t("helper.loginToSystem", [$t("app.name")])
              }}</v-card-subtitle
            >
            <v-card-text>
              <validation-observer ref="form" v-slot="{ handleSubmit, reset }">
                <form
                  @submit.prevent="handleSubmit(signin)"
                  @reset.prevent="reset"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('base.email')"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="state.email"
                      :error-messages="errors"
                      :label="$t('base.email')"
                      autocomplete="off"
                      filled
                      rounded
                      prepend-inner-icon="mdi-email-outline"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('authen.password')"
                    rules="required|min:4"
                  >
                    <p class="ma-0 text-right">
                      <v-btn
                        text
                        small
                        class="text-capitalize"
                        color="primary"
                        to="/auth/forgot"
                        >{{ $t("authen.forgetPassword") }}</v-btn
                      >
                    </p>
                    <v-text-field
                      v-model="state.password"
                      :append-icon="
                        state.showPwd
                          ? 'mdi-eye-outline'
                          : 'mdi-eye-off-outline'
                      "
                      :type="state.showPwd ? 'text' : 'password'"
                      :error-messages="errors"
                      :label="$t('authen.password')"
                      @click:append="state.showPwd = !state.showPwd"
                      filled
                      rounded
                      prepend-inner-icon="mdi-key-outline"
                      autocomplete="off"
                    ></v-text-field>
                  </validation-provider>
                  <div class="mt-6 d-flex justify-space-between">
                    <v-btn
                      text
                      small
                      class="text-capitalize"
                      color="primary"
                      router
                      to="/auth/signup"
                      >{{ $t("authen.createAccount") }}</v-btn
                    >
                    <v-btn
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                      color="primary"
                      depressed
                    >
                      {{ $t("authen.login") }}
                    </v-btn>
                  </div>
                </form>
              </validation-observer>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <core-footer
      :dark="backgroudBg ? true : false"
      :bg="'transparent'"
      :absolute="true"
      :show-to-top="true"
      :showlink="false"
    />

    <!-- <base-wee-snackbar
      v-model="snackbar"
      :color="statusState.status ? 'success' : 'error'"
      :text="statusState.message"
    /> -->
    <wee-toast ref="weeToastRef"></wee-toast>
  </div>
</template>

<script>
import { hashSha512 } from "@/plugins/util";
import AuthService from "@/api/AuthService";
import {
  reactive,
  ref,
  computed,
  // watch,
  watchEffect,
  onMounted
  // onUnmounted
} from "@vue/composition-api";
import { ACTION_SIGN_IN, ACTION_SIGN_OUT } from "@/store/const";
import useBase from "@/composition/UseBase";
export default {
  name: "SignIn",
  components: {
    WeeToast: () => import("@/components/WeeToast"),
    CoreFooter: () => import("@/views/App/components/core/Footer"),
    CoreLocale: () => import("@/views/App/components/core/Locale")
  },
  setup(props, { refs, root }) {
    // setup(props, context) {
    const { $store, $router } = root;
    const { toast } = useBase(refs, root);
    const authService = new AuthService();
    // State
    const state = reactive({
      email: "",
      password: "",
      showPwd: false
    });
    const statusState = reactive({
      status: true,
      message: null
    });

    const loginState = computed(() => $store.state.user.loginState);
    watchEffect(() => {
      if (loginState.value) {
        setTimeout(() => {
          $router.push(`/`);
        }, 500);
      }
    });

    const backgroudBg = ref(false);
    const loading = ref(false);
    // const snackbar = ref(false);

    // Hooks
    onMounted(() => {
      $store.dispatch(ACTION_SIGN_OUT);
    });
    // onUnmounted(() => {
    // });
    const signin = async () => {
      loading.value = true;
      const hashPwd = await hashSha512(state.password);
      const postData = {
        _u: state.email,
        _p: hashPwd
        // _p: state.password
      };
      const { response, error } = await authService.signin(postData);
      loading.value = false;

      if (error) {
        // statusState.status = false;
        // statusState.message = error;
        toast(false, error, { timeout: 5000 });
        return;
      }

      toast(response.status, response.message, { timeout: 5000 });
      if (response) {
        if (statusState.status && response.userData) {
          $store.dispatch(ACTION_SIGN_IN, response.userData);
        }
        // statusState.status = response.status;
        // statusState.message = response.message;
      }
      // snackbar.value = true;
    };

    return {
      loginState,
      state,
      statusState,
      backgroudBg,
      loading,
      signin
      // snackbar
    };
  }
};
</script>

<style lang="sass">
.login-filter
  background-image: url('https://images.pexels.com/photos/2440024/pexels-photo-2440024.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'), linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7))
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  .v-footer
    border-top: transparent !important
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase

.login
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7))
  background-size: cover
</style>